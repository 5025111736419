import React from 'react';

export default () => {
  return (
    // <div>
    //   <img
    //     src={spinner}
    //     alt="Loading..."
    //     style={{ width: '120px', margin: 'auto', display: 'block' }}
    //   />
    // </div>
    <div className="loader-frame">
      <div className="loader1" id="loader1" />
      <div className="loader2" id="loader2" />
    </div>
  );
};
