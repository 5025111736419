import React, { Component } from 'react'
import Layout from '../components/layout/Layout'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Spinner from '../components/common/Spinner'
import { navigate } from 'gatsby'

class contact extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      email: '',
      body: '',
      errors: {},
      loading: false,
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()
    let newMessage = {
      name: this.state.name,
      email: this.state.email,
      body: this.state.body,
    }
    let errors = {}
    // Validate and set errors if any
    if (newMessage.name.trim().length === 0) {
      errors.name = 'Who is sending this message'
    }
    if (newMessage.email.trim().length !== 0) {
      let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!newMessage.email.match(regEx)) {
        errors.email = 'Must be a valid email'
      }
    }
    if (newMessage.body.trim().length === 0) {
      errors.body = 'A message without a message is not a message'
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        loading: true,
      })
      // if we have no errors, send message and redirect
      fetch(
        'https://us-central1-ahmedhadjou-36d3f.cloudfunctions.net/sendMessage',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newMessage),
        }
      )
        .then(() => {
          this.setState({
            loading: false,
          })
          navigate('/')
        })
        .catch(err => console.error(err))
    } else {
      this.setState({
        errors: errors,
      })
    }
  }

  render() {
    const { errors } = this.state
    let messageForm

    if (this.state.loading) {
      messageForm = <Spinner />
    } else {
      messageForm = (
        <form onSubmit={this.onSubmit}>
          <div className="input-group">
            <div className="input-div col-md-6 col-sm-6">
              <input
                autoComplete="name"
                type="text"
                name="name"
                className={classnames('form-control', {
                  'is-invalid': errors.name,
                })}
                placeholder="Name"
                value={this.state.name}
                onChange={this.onChange}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
            <div className="input-div col-md-6 col-sm-6">
              <input
                autoComplete="email"
                type="text"
                name="email"
                className={classnames('form-control', {
                  'is-invalid': errors.email,
                })}
                placeholder="Email (optional)"
                value={this.state.email}
                onChange={this.onChange}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            <div className="input-div col-md-12 col-sm-12">
              <textarea
                type="text"
                name="body"
                className={classnames('form-control form-control-lg', {
                  'is-invalid': errors.body,
                })}
                placeholder="Message"
                value={this.state.body}
                onChange={this.onChange}
              />
              {errors.body && (
                <div className="invalid-feedback">{errors.body}</div>
              )}
            </div>
          </div>

          <input
            type="submit"
            className="btn btn-primary custom-button"
            id="submit-button"
            value="Send"
          />
        </form>
      )
    }
    return (
      <Layout pageTitle="Contact me" contact={true}>
        <h1 className="section-title">Contact</h1>
        <p className="subtitle">
          Whether you would like to hire me, further enquire about any of my
          work, or you just want to say hello, I would love to hear from you :)
        </p>
        <div className="align-mid">
          <p>
            <a href="mailto:info@ahmedhadjou.com">info@ahmedhadjou.com</a> -{' '}
            <span className="green-text">+44 (0)7459188428</span>
          </p>
        </div>
        <br />
        {messageForm}
      </Layout>
    )
  }
}

contact.propTypes = {
  errors: PropTypes.object,
}

export default contact
